/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1e7zuz8cuc.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "aidashboardapi",
            "endpoint": "https://bo1b7mlv0k.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "rolespoliciespermissionsapi",
            "endpoint": "https://89vd5itpkh.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "timeandattendanceapi",
            "endpoint": "https://opl6kxfva3.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "usersbucketsgroupsapi",
            "endpoint": "https://k2xc0t2pp0.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://staezjvchvbxlhctzeoowdhd5q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:aa398590-2230-4297-aaff-1ae186e76e70",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6lch2S1Xy",
    "aws_user_pools_web_client_id": "1kjjrkt0foqdhdffrhvhl64oj",
    "oauth": {
        "domain": "itelligencecx-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://testing.itelligencecx.com/",
        "redirectSignOut": "https://testing.itelligencecx.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
